import React from 'react'
import { Table } from 'antd'
import './index.less'

const ConfigTable = ({ title, dataSource, columns, rowKey, loading, ...props }) => {
  if (rowKey === false) {
    rowKey = undefined
  } else {
    rowKey = rowKey ? rowKey : 'id'
  }
  return (
    <div className="setting-table">
      <Table
        columns={columns}
        rowKey={rowKey}
        locale={{ emptyText: '暂无数据' }}
        dataSource={dataSource}
        pagination={false}
        loading={loading}
        {...props}
      />
    </div>
  )
}
export default ConfigTable
